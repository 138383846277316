/* styles.css or Login.css */
body {
  margin: 0;
  padding: 0;
}

.login-page {
  background-image: url("./assets/icons/loginbg.jpg"); /* Path to your image */
  background-size: cover; /* Makes the image cover the entire element */
  background-position: center; /* Centers the image */
  height: 100vh; /* Full viewport height */
  display: flex;
  justify-content: center; /* Centers content horizontally */
  align-items: center; /* Centers content vertically */
  color: white; /* Optional text color for contrast */
}
